import React from 'react';
import './TermsAndConditions.css'; // Import your CSS file
import PaidTermsOfService from './PaidTermsOfService';

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <h1>TERMS AND CONDITIONS FOR NEARBY ENTERTAINMENT</h1>
      <p>
        1. Introduction - Welcome to Nearby Entertainment. By accessing and using our website, you agree to comply with and be bound by the following terms and conditions. Please review these terms carefully. If you do not agree with these terms, you should not use our website.
      </p>
      <p>
        2. Acceptance of Agreement - You agree to the terms and conditions outlined in this Terms and Conditions Agreement ("Agreement") with respect to our website. This Agreement may be amended at any time by us without specific notice to you. The latest Agreement will be posted on the website, and you should review this Agreement prior to using the website.
      </p>
      <p>
        3. Contacting You - By using our website and providing your contact information, you hereby grant us the permission to contact you regarding any updates, promotions, news, or other related matters. We may contact you via email, phone, or other means of communication that you have provided.
      </p>
      <p>
        4. Privacy Policy - Your privacy is of utmost importance to us. While we may contact you as mentioned above, we pledge not to sell, distribute, or share your personal information with any third parties, except as required by law. For more details on how we handle your personal information, please refer to our Privacy Policy.
      </p>
      <p>
        5. Copyright - The content, organization, graphics, design, and other matters related to the website are protected under applicable copyrights, trademarks, and other proprietary rights. The copying, redistribution, use, or publication by you of any such matters or any part of the website is strictly prohibited.
      </p>
      <p>
        6. Limitation of Liability - Nearby Entertainment shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or the inability to use the website or for the cost of procurement of substitute goods and services or resulting from any goods or services purchased or obtained or messages received or transactions entered into through the website or resulting from unauthorized access to or alteration of your transmissions or data, including but not limited to, damages for loss of profits, use, data, or other intangible, even if we have been advised of the possibility of such damages.
      </p>
      <p>
        7. Indemnification - You agree to indemnify, defend, and hold us and our partners, attorneys, staff, and affiliates harmless from any liability, loss, claim, and expense, including reasonable attorney's fees, related to your violation of this Agreement or use of the website.
      </p>
      <p>
        8. Governing Law - This Agreement shall be treated as though it were executed and performed in the State of South Carolina, USA, and shall be governed by and construed in accordance with the laws of South Carolina, USA (without regard to conflict of law principles).
      </p>
      <p>
        9. Miscellaneous - If any provision of this Agreement is deemed unlawful, void, or unenforceable for any reason, then that provision shall be deemed severable from this Agreement and shall not affect the validity and enforceability of any remaining provisions.
      </p>

      <PaidTermsOfService />
    </div>
  );
};

export default TermsAndConditions;
