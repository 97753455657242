import { Button, Card, CardContent, TextField } from "@mui/material";
import {makeStyles} from '@material-ui/core/styles';
import CardInput from "./CardInput";
import ReactLoading from 'react-loading';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { useEffect, useState } from "react";
import { Axios } from "../core/axios";
const useStyles = makeStyles({
    root: {
      maxWidth: '600px',
      margin: 'auto',
      marginTop: '5em',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'flex-start',
    },
    div: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'flex-start',
      justifyContent: 'space-between',
    },
    button: {
      margin: '2em auto 1em',
    },
  });

const AddPaymentMethod = () => {
    const classes = useStyles();
    const elements = useElements();

    const stripe = useStripe();
    const [email, setEmail] = useState('');
    const [selectedPlan, setSelectedPlan] = useState('basic');
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);

    const [subscriptionData, setSubscriptionData] = useState(null);

    const priceings = {
        basic: {
          amount: 10,
        },
        premium: {
          amount: 20,
        }
      }
    
      useEffect(async() => {
        try{
          var {data} = await Axios.get(`/stripe/getUserSubscription`);
          setSubscriptionData(data.subscription);
          console.log(data.subscription);
        }catch(e){
            console.log(e);
        }
    }, []);
    useEffect(() => {
        try {
        const user = JSON.parse(localStorage.getItem("user"));
        setEmail(user.email);
        } catch (error) {
            console.log(error);
            window.location.href = "/login";
            localStorage.setItem("redirect", "/subscription");
        }
    }, []);
    

    const handlePlanChange = (plan) => {
        setSelectedPlan(plan);
    }

    const handleSubmitSub = async (event) => {
        setIsPaymentLoading(true);
        if (!stripe || !elements) {
          return;
        }
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            email: email,
          },
        });
    
        if (result.error) {
          console.log(result.error.message);
          setIsPaymentLoading(false);
        } else {
          const res = await Axios.post('/stripe/subscription', {'payment_method': result.paymentMethod.id, 'email': email, 'selectedPlan': selectedPlan, 'subscriptionId': subscriptionData.subscription_id});
          // eslint-disable-next-line camelcase
          const {client_secret, status} = res.data;
    
          if (status === 'requires_action') {
            stripe.confirmCardPayment(client_secret).then(function(result) {
              if (result.error) {
                console.log('There was an issue!');
                console.log(result.error);
                // Display error message in your UI.
                // The card was declined (i.e. insufficient funds, card has expired, etc)
              } else {
                console.log('You got the money!');
                // setStripeResult(result);
                window.location.href = "/";
                // Show a success message to your customer
              }
              setIsPaymentLoading(false);
            });
          } else {
            console.log('You got the money!');
            // setStripeResult(res.data);
            window.location.href = "/";
            // No additional information was needed
            // Show a success message to your customer
            setIsPaymentLoading(false);
          }
        }
      };


    if (!subscriptionData) {
        return (
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
            }}>Loading...</div>
        )
    }

    if(!subscriptionData.subscription_id){
        window.location.href = "/home";
        return (
            <></>
        )
    }
    
    
    return (
        <Card className={classes.root} style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            width: '100%',
        }}>
          <CardContent className={classes.content}>
            <TextField
              label='Email'
              id='outlined-email-input'
              helperText={`Email you'll recive updates and receipts on`}
              margin='normal'
              variant='outlined'
              type='email'
              disabled={true}
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
            <CardInput />
    
            {/* <div className='subscription-header'>
              <h1>Subscription Plans</h1>
              <p>Choose a plan that works for you</p>
            </div> */}
          {/* <div className="subscription">
                <div
                  className={`plan ${selectedPlan === 'basic' ? 'selected' : ''}`}
                  onClick={() => handlePlanChange('basic')}
                >
                  <img src={'/images/OPENING_ACT.png'} alt="OPENING_ACT" className='plan-image' />
                   <h2>OPENING ACT</h2>
                  <p>10 Listings/Month</p>
                  <div className='plan-price-root'><h1>$10.00</h1><span className='per-month-color'>per month</span></div>
                  
                </div>
                <div
                  className={`plan ${selectedPlan === 'premium' ? 'selected' : ''}`}
                  onClick={() => handlePlanChange('premium')}
                >
                  <img src={'/images/ENCORE_ELITE.png'} alt="ENCORE_ELITE" className='plan-image' />
                  <h2>ENCORE ELITE</h2>
                  <p>31 Listings/Month</p>
                  <div className='plan-price-root'><h1>$20.00</h1><span>per month</span></div>
                </div>
              </div> */}
            <div className={classes.div} style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              {
                isPaymentLoading?<div style={{marginTop: "10px"}}><ReactLoading type={"spin"} color="#000" height={'50px'} width={'50px'} /></div>:
              <Button variant="contained" color="primary"
              style={{
                margin: '1em auto 1em',
                width: '100%',
                height: '50px',
              }}
              className={classes.button} onClick={handleSubmitSub}>
                {"Add Payment Method"}
              </Button>
      }
            </div>
          </CardContent>
        </Card>
      );
}

export default AddPaymentMethod;