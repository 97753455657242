import React from 'react';
import './PaidTermsOfService.css'; // Import your CSS file

const PaidTermsOfService = () => {
  return (
    <div className="paid-terms-of-service">
      <h1>SUBSCRIPTION TERMS OF SERVICE</h1>
      <p>
        1. Introduction - Welcome to Nearby Entertainment. By signing up and using our platform, you agree to the following Terms of Service Agreement ("Agreement"). Please read them carefully.
      </p>
      <p>
        2. Free Trial Subscription:
      </p>
      <ul>
        <li>2.1 Your free trial subscription will expire 90 days after signup or after 90 listings. Whichever comes first.</li>
      </ul>
      <p>
        3. Monthly Subscription
      </p>
      <ul>
        <li>3.1. Your subscription will renew automatically at the beginning of each month.</li>
        <li>3.2. You may cancel your subscription at any time.</li>
        <li>3.3. Should you choose to cancel after a billing cycle has commenced, we will not be able to issue a refund.</li>
        <li>3.4. Following cancellation, you are permitted to continue using your subscription until the end of that month's billing cycle.</li>
      </ul>
      <p>
        4. Service Reliability
      </p>
      <ul>
        <li>4.1. Nearby Entertainment operates using multiple web-based services.</li>
        <li>4.2. We strive to ensure continuous operation of our platform, but we cannot be held responsible for any service interruptions or outages due to third-party servers or other external factors.</li>
      </ul>
      <p>
        5. Content and Conduct Standards
      </p>
      <ul>
        <li>5.1. Nearby Entertainment is committed to maintaining a family-friendly platform.</li>
        <li>5.2. We reserve the right to remove any listings we find inappropriate, vulgar, or hateful.</li>
        <li>5.3. Any violation of the Terms of Service, abuse of the subscription, or misuse of free trial policies may lead to termination of your account.</li>
      </ul>
      <p>
        6. General Provisions
      </p>
      <ul>
        <li>6.1. By using our services, you agree to abide by all our platform guidelines and policies.</li>
        <li>6.2. We reserve the right to modify or terminate the service for any reason, without notice at any time.</li>
      </ul>
      <p>
        By proceeding with the signup process and using our platform and website, you acknowledge that you have read, understood, and agreed to be bound by the above Terms of Service and Conditions Agreement.
      </p>
    </div>
  );
};

export default PaidTermsOfService;
