import React from 'react';
import './PrivacyPolicy.css'; // Import your CSS file

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>PRIVACY POLICY FOR NEARBY ENTERTAINMENT</h1>
      <p>
        1. Introduction - At Nearby Entertainment, we respect your privacy and are committed to protecting it. This Privacy Policy outlines our practices concerning the collection, use, and sharing of your personal information. By visiting our website, you accept the practices described in this Policy.
      </p>
      <p>
        2. Information We Collect - We may collect personal information that you voluntarily provide to us, such as your name, email address, phone number, and other personal details. This information is primarily needed to deliver our services, respond to inquiries, and offer you relevant information.
      </p>
      <p>
        3. Use of Your Information - We may use the information we collect from you for the following purposes:
      </p>
      <ul>
        <li>- To provide our services and respond to your requests or inquiries.</li>
        <li>- To send updates, promotions, news, or other related communications. By providing your contact details, you consent to be contacted by us.</li>
        <li>- To improve our website and services by analyzing how our website is used.</li>
      </ul>
      <p>
        4. Protection of Your Information - We implement a variety of security measures to maintain the safety of your personal information. We use state-of-the-art encryption and other security technologies to protect your data from unauthorized access, use, or disclosure.
      </p>
      <p>
        5. Non-disclosure of Information - We value your trust in providing us with your personal information. Thus, we want to assure you that we will not sell, trade, or otherwise transfer your personal information to outside parties, except as outlined in this Policy or when we have your explicit consent.
      </p>
      <p>
        6. Third-party Links - Our website may contain links to third-party websites. These third-party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these external sites.
      </p>
      <p>
        7. Changes to this Privacy Policy - We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
      </p>
      <p>
        8. Contact Us - If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:AlexaNearbyEntertainment@gmail.com">AlexaNearbyEntertainment@gmail.com</a>.
      </p>
      <p>
        By using the Nearby Entertainment website, you consent to our Privacy Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
